import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <body>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <script
        src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`}
        async
        defer
      ></script>

      <Header></Header>
      {children}
      <Footer></Footer>
    </body>
  );
};

export default Layout;
