import React from "react";

import client from "../apollo/client";
import { ApolloProvider } from "@apollo/client";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../components/layouts/Layout";

import "react-toastify/dist/ReactToastify.css";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <ToastContainer
      draggable={false}
      autoClose={4000}
      pauseOnHover={false}
      position={toast.POSITION.TOP_CENTER}
    />
    <Layout>{element}</Layout>
  </ApolloProvider>
);
